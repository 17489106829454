<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <ServerError v-if="ServerError" />

    <v-layout wrap>
      <v-flex xs12>
        <v-card>
          <v-layout wrap pa-5>
            <v-flex xs12 md6>
              <v-layout wrap>
                <v-flex xs4>
                  <span class="sbold"> ASW Day</span>
                </v-flex>
                <v-flex xs8>
                  <span class="regular">{{ allData.aswDay }} </span>
                </v-flex>
                <v-flex xs4>
                  <span class="sbold">Distance Covered</span> &nbsp;
                </v-flex>
                <v-flex xs8>
                  <span class="regular">{{ allData.distanceWalked }} (m) </span>
                </v-flex>
                <v-flex xs4>
                  <span class="sbold"> Hours Dedicated</span>
                </v-flex>
                <v-flex xs8>
                  <span class="regular">{{ allData.hoursDedicated }} </span>
                </v-flex>
                <v-flex xs4>
                  <span class="sbold"> Observation : </span> &nbsp;
                </v-flex>
                <v-flex xs8>
                  <span class="regular">{{ allData.observation }} </span>
                </v-flex>
                <v-flex xs4>
                  <span class="sbold">Division:</span> &nbsp;
                </v-flex>
                <v-flex xs8>
                  <span v-if="allData.divisionId" class="regular">{{
                    allData.divisionId.divisionName
                  }}</span>
                </v-flex>
                <v-flex xs4> <span class="sbold">Range:</span> &nbsp; </v-flex>
                <v-flex xs8>
                  <span class="regular" v-if="allData.rangeId">{{
                    allData.rangeId.rangeName
                  }}</span>
                </v-flex>
                <v-flex xs4> <span class="sbold">Beat:</span> &nbsp; </v-flex>
                <v-flex xs8>
                  <span class="regular" v-if="allData.beatId">{{
                    allData.beatId.beatName
                  }}</span>
                </v-flex>

                <v-flex xs4>
                  <span class="sbold"> Compartment : </span>
                </v-flex>
                <v-flex xs8>
                  <span class="regular"
                    >{{ allData.compartment }}
                  </span></v-flex
                >
              </v-layout>
            </v-flex>
            <v-flex xs12 md6 align-self-center>
              <v-card class="" elevation="4" style="background-color: grey">
                <v-layout wrap style="color: white">
                  <v-flex xs12 px-2 pt-1>
                    <span class="sbold">Name:</span> &nbsp;
                    <span v-if="allData.userId" class="regular"
                      >{{ allData.userId.firstName }} &nbsp;{{
                        allData.userId.lastName
                      }}</span
                    >
                  </v-flex>
                  <v-flex xs12 px-2 v-if="allData.userId">
                    <span class="sbold">Phone:</span> &nbsp;
                    <span class="regular">{{ allData.userId.phone }}</span>
                  </v-flex>
                  <v-flex xs12 px-2 pb-1 v-if="allData.userId">
                    <span class="sbold">Email:</span> &nbsp;
                    <span class="regular">{{ allData.userId.email }} </span>
                  </v-flex>
                </v-layout>
              </v-card>
              <v-card
                class="mt-2 pa-3"
                elevation="4"
                style="background-color: grey"
              >
                <v-layout wrap style="color: white">
                  <v-flex xs12 px-2 pt-1>
                    <span class="sbold">Start Date:</span> &nbsp;
                    <span class="regular"
                      >{{ new Date(allData.startTime).toLocaleString() }}
                    </span>
                    &nbsp;
                    <span class="sbold"> <v-icon>mdi-map-marker</v-icon> </span>
                    &nbsp;
                    <span class="regular" v-if="allData.startGps">
                      {{ allData.startGps[1] }},{{ allData.startGps[0] }}
                    </span>
                  </v-flex>
                  <v-flex xs12 py-2 px-4>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs12 px-2 pt-1>
                    <span class="sbold">End Date:</span> &nbsp;
                    <span class="regular"
                      >{{ new Date(allData.endTime).toLocaleString() }} </span
                    >&nbsp; &nbsp;
                    <span class="sbold">
                      <v-icon> mdi-map-marker</v-icon>
                    </span>
                    &nbsp;
                    <span class="regular" v-if="allData.endGps">
                      {{ allData.endGps[1] }} ,{{ allData.endGps[0] }}</span
                    >
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex xs10 pt-3>
        <span class="bold" style="text-transform: uppercase">
          <h3>Participents</h3></span
        >
      </v-flex>
      <v-flex xs2 lg1 text-rght align-self-end text-right pb-1>
        <download-excel :data="excelData" :fields="json_fields">
          <v-btn x-small dense dark>
            Download Excel <v-icon x-small>mdi-download</v-icon></v-btn
          >
        </download-excel>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12>
        <v-data-table
          style="cursor: pointer"
          :headers="headers"
          :items="participents"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:item.create_date="{ item }">
            <span>{{ new Date(item.create_date).toLocaleString() }}</span>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <!-- <v-layout wrap>
      <v-flex xs12>
        <v-card>
          <v-layout wrap pa-5>
            <v-flex md2 lg3 v-for="(item, i) in participents" :key="i">
              <v-layout wrap pa-1>
                <v-flex xs12>
                  <span class="sbold">Name:</span> &nbsp;
                  <span class="regular">{{ item.name }}</span>
                </v-flex>
                <v-flex xs12>
                  <span class="sbold">Designation:</span> &nbsp;
                  <span class="regular">{{ item.designation }}</span>
                </v-flex>
                <v-flex xs12>
                  <span class="sbold">Contact Number:</span> &nbsp;
                  <span class="regular">{{ item.contactNumber }}</span>
                </v-flex>
                <v-flex xs12>
                  <span class="sbold">Area InCharge:</span> &nbsp;
                  <span class="regular">{{ item.areaInCharge }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout> -->
    <v-layout wrap pt-3>
      <v-flex xs12>
        <h3 class="bold" style="text-transform: uppercase">
          Snare Reports - {{ snareReports.length }}
        </h3>
      </v-flex>

      <v-flex xs12 v-if="snareReports.length < 1" text-center>
        <h4>No Snare Reports.........!</h4>
      </v-flex>

      <template v-else>
        <v-flex xs12 v-for="(item, i) in snareReports" :key="i" pt-1>
          <v-card>
            <v-layout wrap pa-5>
              <v-flex xs12>
                <h4 style="border-bottom: 3px solid black">
                  SNARE NO : {{ i + 1 }}
                </h4>
              </v-flex>

              <v-flex xs12 sm6 pt-3 pr-1 align-self-center text-center>
                <v-card outlined v-if="item.animalMarkPhoto.length > 0">
                  <v-card-title>Animal Marks</v-card-title>
                  <v-card-text>
                    <v-layout wrap>
                      <v-flex
                        pa-1
                        xs12
                        md4
                        lg4
                        v-for="(item2, k) in item.animalMarkPhoto"
                        :key="k"
                      >
                        <v-img
                          :src="mediaURL + item2"
                          @click="(imageDialog = true), (dialogImage = item2)"
                          style="cursor: pointer"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color=" #FDAC53"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
                <span v-else class="py-2" style="font-family: pregular">
                  No animal mark uploads....
                </span>
              </v-flex>
              <v-flex xs12 sm6 pt-3 pl-1 pb-1>
                <v-card outlined v-if="item.photo.length > 0">
                  <v-card-title>Snare Photos</v-card-title>
                  <v-card-text>
                    <v-layout wrap>
                      <v-flex
                        xs12
                        md4
                        lg4
                        pa-1
                        v-for="(item3, j) in item.photo"
                        :key="j"
                      >
                        <v-img
                          :src="baseURL + '/file/get/' + item3"
                          @click="(imageDialog = true), (dialogImage = item3)"
                          style="cursor: pointer"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color=" #FDAC53"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
                <span v-else class="py-2" style="font-family: pregular">
                  No snare photo uploads....
                </span>
              </v-flex>
              <v-flex xs12 md3 pt-3>
                <span class="sbold">Snare Type </span> &nbsp;
              </v-flex>
              <v-flex xs12 md9 pt-3>
                <span class="regular" style="text-transform: capitalize">{{
                  item.snareTypes.name
                }}</span>
              </v-flex>
              <template v-if="item.snareTypes.name == 'Multiple Loop'">
                <v-flex xs12 md3 pt-1>
                  <span class="sbold">Loop Count</span> &nbsp;
                </v-flex>
                <v-flex xs12 md9 pt-3>
                  <span class="regular" style="text-transform: capitalize"
                    >{{ item.snareLoop }}
                  </span>
                </v-flex>
              </template>
              <v-flex xs12 pt-1 md3>
                <span class="sbold">Snare Material</span> &nbsp;
              </v-flex>
              <v-flex xs12 md9>
                <span class="regular" style="text-transform: capitalize">{{
                  item.snareMaterial
                }}</span>
              </v-flex>

              <v-flex xs12 md3 pt-1>
                <span class="sbold">Near by Village </span> &nbsp;
              </v-flex>
              <v-flex xs12 md9>
                <span class="regular"
                  >{{ item.nearByVillageDistance }} (m)</span
                >
              </v-flex>

              <v-flex xs12 md3 pt-1>
                <span class="sbold">Near by Water Source </span> &nbsp;
              </v-flex>
              <v-flex xs12 md9>
                <span class="regular" style="text-transform: capitalize"
                  >{{ item.nearByWaterSource }}
                </span>
                <span> (m)</span>
              </v-flex>
              <v-flex xs12 md3 pt-1>
                <span class="sbold">Animal Mark </span> &nbsp;
              </v-flex>
              <v-flex xs12 md9>
                <span class="regular" style="text-transform: capitalize">{{
                  item.animalMark
                }}</span>
              </v-flex>

              <v-flex xs12 md3 pt-1>
                <span class="sbold">Forest Type </span> &nbsp;
              </v-flex>
              <v-flex xs12 md9>
                <span class="regular" style="text-transform: capitalize">{{
                  item.forestType
                }}</span>
              </v-flex>

              <v-flex xs12 md3 pt-1>
                <span class="sbold">Land Scape </span> &nbsp;
              </v-flex>
              <v-flex xs12 md9>
                <span class="regular" style="text-transform: capitalize">{{
                  item.landScape
                }}</span>
              </v-flex>
              <v-flex xs12 md3 pt-1>
                <span class="sbold">Remarks </span> &nbsp;
              </v-flex>

              <v-flex xs12 md9>
                <span class="regular" style="text-transform: capitalize">{{
                  item.remarks
                }}</span>
              </v-flex>
              <v-flex xs12 md3 pt-1>
                <span class="sbold"> Targeted Sps </span> &nbsp;
              </v-flex>
              <v-flex xs12 md9>
                <span class="regular" style="text-transform: capitalize">{{
                  item.targetedSps
                }}</span>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </template>
    </v-layout>

    <v-layout wrap pt-3>
      <v-flex xs12>
        <h3 class="bold" style="text-transform: uppercase">
          Animal Reports - {{ animalRescue.length }}
        </h3>
      </v-flex>

      <v-flex xs12 v-if="animalRescue.length < 1" text-center>
        <h4>No Animal Reports.........!</h4>
      </v-flex>

      <template v-else>
        <v-flex xs12 v-for="(item, i) in animalRescue" :key="i" pt-1>
          <v-card>
            <v-layout wrap pa-5>
              <v-flex xs12>
                <h4 style="border-bottom: 3px solid black">
                  RESCUE NO : {{ i + 1 }}
                </h4>
              </v-flex>

              <!-- <v-flex xs12 sm6 pt-3 pr-1 align-self-center text-center>
                <v-card outlined v-if="item.animalMarkPhoto.length > 0">
                  <v-card-title>Animal Marks</v-card-title>
                  <v-card-text>
                    <v-layout wrap>
                      <v-flex
                        pa-1
                        xs12
                        md4
                        lg4
                        v-for="(item2, k) in item.animalMarkPhoto"
                        :key="k"
                      >
                        <v-img
                          :src="mediaURL + item2"
                          @click="(imageDialog = true), (dialogImage = item2)"
                          style="cursor: pointer"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color=" #FDAC53"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
                <span v-else class="py-2" style="font-family: pregular">
                  No animal mark uploads....
                </span>
              </v-flex> -->
              <v-flex xs12 pt-3 pl-1>
                <v-card outlined v-if="item.photo.length > 0">
                  <v-card-title>Animal Photos</v-card-title>
                  <v-card-text>
                    <v-layout wrap>
                      <v-flex
                        xs12
                        md4
                        lg4
                        pa-1
                        v-for="(item3, j) in item.photo"
                        :key="j"
                      >
                        <v-img
                          id="canvasImg"
                          height="200px"
                          contain
                          :src="baseURL + '/file/get/' + item3"
                          @click="(imageDialog = true), (dialogImage = item3)"
                          style="cursor: pointer"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color=" #FDAC53"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
                <span v-else class="py-2" style="font-family: pregular">
                  No rescue photo uploads....
                </span>
              </v-flex>
              <v-flex xs12 md3 pt-3>
                <span class="sbold">Snare Type </span> &nbsp;
              </v-flex>
              <v-flex xs12 md9 pt-3>
                <span class="regular" style="text-transform: capitalize">{{
                  item.snareTypes.name
                }}</span>
                <span></span>
              </v-flex>
              <!-- <template v-if=" item.snareTypes.name == 'Multiple Loop'">
               <v-flex xs12 md3 pt-1>
                <span class="sbold">Loop Count</span> &nbsp;
              </v-flex>
              <v-flex xs12 md9 pt-3>
                <span class="regular" style=" text-transform: capitalize;">{{ item.snareLoop }} </span>
              </v-flex>
              </template> -->

              <v-flex xs12 md3 pt-1>
                <span class="sbold">Animal Condition</span> &nbsp;
              </v-flex>
              <v-flex xs12 md9 pt-1>
                <span class="regular" style="text-transform: capitalize">{{
                  item.animalCondition
                }}</span>
              </v-flex>

              <v-flex xs12 md3 pt-1>
                <span class="sbold">Animal Sps </span> &nbsp;
              </v-flex>
              <v-flex xs12 md9 pt-1>
                <span class="regular" style="text-transform: capitalize">{{
                  item.animalSps
                }}</span>
              </v-flex>
              <v-flex xs12 md3 pt-1>
                <span class="sbold">Animal Stage </span> &nbsp;
              </v-flex>
              <v-flex xs12 md9 pt-1>
                <span class="regular" style="text-transform: capitalize">{{
                  item.animalStage
                }}</span>
              </v-flex>

              <v-flex xs12 md3 pt-1>
                <span class="sbold">Report Type </span> &nbsp;
              </v-flex>
              <v-flex xs12 md9 pt-1>
                <span class="regular" style="text-transform: capitalize">{{
                  item.reportType
                }}</span>
              </v-flex>
              <!-- <v-flex xs12 md3 pt-1>
                <span class="sbold">Snare Type </span> &nbsp;
              </v-flex>
              <v-flex xs12 md9 pt-3>
                <span class="regular" style=" text-transform: capitalize;">{{ item.snareTypes }}</span>
              </v-flex> -->
            </v-layout>
          </v-card>
        </v-flex>
      </template>
    </v-layout>

    <v-dialog v-model="imageDialog" width="600px">
      <v-card>
        <v-img :src="mediaURL + dialogImage">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color=" #FDAC53"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      json_fields: {
        Name: "name",
        Designation: "designation",
        Contact_No: "contactnumber",
        Area_Incharge: "areaincharge",
      },
      exel: {
        name: "",
        designation: "",
        contactnumber: "",
        areaincharge: "",
      },

      excelData: [],
      imageDialog: false,
      dialogImage: null,
      appLoading: false,
      ServerError: false,
      records: [],
      gmap2: null,
      latt: null,
      lonn: null,
      icons: "",
      photos: [],
      user: [],
      video: null,
      boatDocument: null,
      GroupData: [],
      allData: [],
      participents: [],
      snareReports: [],
      animalRescue: [],
      gmap: null,
      headers: [
        { text: "Name", value: "name" },
        {
          text: "Designation ",
          align: "start",
          sortable: false,
          value: "designation",
        },
        {
          text: "ContactNumber ",
          align: "start",
          sortable: false,
          value: "contactNumber",
        },
        {
          text: "Area InCharge:",
          align: "start",
          sortable: false,
          value: "areaInCharge",
        },

        // { text: " Not Removed Traps", value: "noOfTrapsNotRemoved" },
      ],
      documentFile1: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/admin/view/report/details",
        method: "post",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          page: this.page,
          limit: 25,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.allData = response.data.data;
            this.participents = response.data.data.participents;

            for (let i = 0; i < this.participents.length; i++) {
              this.exel.name = this.participents[i].name;
              // this.exel.beat = this.participents[i].divisionId.divisionName;
              this.exel.designation = this.participents[i].designation;

              this.exel.contactnumber = this.participents[i].contactNumber;
              this.exel.areaincharge = this.participents[i].areaInCharge;

              this.excelData.push(JSON.parse(JSON.stringify(this.exel)));
            }

            this.snareReports = response.data.reports;
            this.animalRescue = response.data.animalRescue;
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style >
.v-data-table,
.v-text-field {
  font-family: pregular;
}
#appp {
  width: 11.5in;
  padding: 1px solid red;
}
</style>